import React from 'react';

import ComingSoon from "./coming-soon/ComingSoon";

function App() {
  return (
    <ComingSoon />
  );
}

export default App;
