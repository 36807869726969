import React from 'react';

import './responsive.css';
import './styles.css';
import './coming-soon.css';

const ComingSoon = () => {
  return (
    <div className="main-area center-text main-content">

      <div className="display-table">
        <div className="display-table-cell">

          <h1 className="title font-white"><b>Coming Soon</b></h1>
          <p className="desc font-white">
            Hi! I'm Piero Divasto and welcome to my website <span role="img" aria-label="smile-face">&#128512;</span>️
          </p>

          <p className="desc font-white">
            It is currently under construction. Soon you will be able to see all
            about me, my work, hobbies, etc. In the meantime, if want to contact me or see what I'm doing you can
            follow me on internet in the links at the bottom <span role="img" aria-label="finger-down">👇</span>
          </p>

          <p className="desc font-white">
            Thank you for coming here and see you soon! <span role="img" aria-label="smile-face">🙂</span>
          </p>

          <ul className="social-btn font-white">
            <li><a href="https://www.linkedin.com/in/pierodivasto/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://twitter.com/morph3o" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.instagram.com/pdivasto/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://slides.com/morph3o" target="_blank" rel="noopener noreferrer">Slides</a></li>
            <li><a href="https://github.com/morph3o" target="_blank" rel="noopener noreferrer">Github</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
